<template>
  <b-breadcrumb :items="items" :class="classNav"></b-breadcrumb>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["classNav", "items"]
};
</script>

<style></style>
