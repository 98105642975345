<template>
  <div class="hero-paginas overflow-hidden">
    <SearchBar
      v-if="$route.path != '/'"
      :isCursoPage="true"
      class="busca-principal-header mt-4"
    />
    <b-container>
      <h1 v-if="title" class="titulo-paginas" v-text="title" />
      <h2 v-if="subtitle" class="subTitulo-paginas" v-text="subtitle" />
      <slot name="breadcrumb" />
    </b-container>
    <slot name="panel-content" />
  </div>
</template>

<script>
import SearchBar from "@/components/shared/SearchBar";

export default {
  props: ["title", "subtitle"],
  components: { SearchBar }
};
</script>

<style></style>
