<template>
  <div>
    <b-form @submit="onSubmit">
      <b-row>
        <b-col cols="12" sm="12" md="6" xl="6">
          <b-form-group
            id="input-group-1"
            label="Qual é o seu nome?*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="form.nome"
              placeholder="Seu nome completo"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Qual é o seu e-mail?*"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              type="email"
              required
              placeholder="Seu email"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Qual é o seu telefone?*"
            label-for="telefone"
          >
            <b-form-input
              id="telefone"
              v-model="form.telefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
              type="tel"
              required
              placeholder="Seu telefone"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" xl="6">
          <b-form-group
            id="input-group-3"
            label="Selecione uma opção:*"
            label-for="input-3"
          >
            <b-form-select
              id="input-3"
              v-model="form.assunto"
              :options="subjects"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-4" label="Digite sua mensagem:">
            <b-form-textarea
              rows="5"
              v-model="form.mensagem"
              id="message"
              placeholder="Conte-nos a sua necessidade"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div id="containerRacapBut" :class="{mx_form_invalido: formInvalido}">
        <div id="recapContato" :class="{mx_recaptcha_failed: !recaptcha}" >
          <template>
            <vue-recaptcha id="containerRecaptcha" @verify="mxVerify" sitekey="6LdGy0khAAAAAMKx3ZTlk0uCWWT7-18NhOBcBYi6"></vue-recaptcha>
            <!-- <vue-recaptcha id="containerRecaptcha" @verify="mxVerify" sitekey="6Lc_68cbAAAAAP2wvYWN1JDWk0CrQiP_O6zHn_9z"></vue-recaptcha> -->
          </template>
          <span class="avisoCaptcha">Complete o Captcha!</span>
        </div>
        <b-button type="submit" class="button-send" pill>
          Entrar em contato
        </b-button>
      </div>
      <b-overlay :show="loading" rounded="sm" no-wrap />
    </b-form>
  </div>
</template>

<script>
import { CONTATO_ASSUNTOS, MENSAGENS } from "@/constants/Constants";
import ContatoService from "@/services/ContatoService";
import VueRecaptcha from 'vue-recaptcha';

const INITIAL_FORM = {
  tipo: "Contato",
  email: "",
  nome: "",
  telefone: "",
  mensagem: "",
  assunto: null,
  typeEmail: "contato"
};
export default {
  props: ["selectValue"],
  components: { VueRecaptcha },
  data() {
    return {
      formInvalido : false,
      recaptcha    : null,
      form: {
        ...INITIAL_FORM
      },
      loading: false,
      subjects: [
        { value: null, text: "Selecione uma opção" },
        ...CONTATO_ASSUNTOS
      ]
    };
  },
  watch: {
    selectValue: "setSelectValue"
  },
  mounted() {
    this.setSelectValue();

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
    document.head.appendChild(recaptchaScript)
  },
  methods: {
    mxVerify( response ) {
      this.recaptcha = response;
    },

    setSelectValue() {
      this.form.assunto = CONTATO_ASSUNTOS.includes(this.selectValue)
        ? this.selectValue
        : null;
    },
    async onSubmit(evt) {
      evt.preventDefault();
      try {

        // validacao recaptcha
        if(!this.recaptcha){
          this.formInvalido = true;
          return false;
        }

        this.loading = true;
        let response = await ContatoService.sendEmail(this.form);
        this.loading = false;
        this.form = INITIAL_FORM;
        if (response?.data?.success) {
          this.$bvModal.msgBoxOk(MENSAGENS.FORM_ENVIO, {
            title: "Confirmação",
            size: "sm",
            buttonSize: "sm",
            okTitle: "Continuar",
            okVariant: "success",
            headerClass: "p-2 cor-secundaria",
            footerClass: "p-2",
            centered: true
          });
          // reseta recaptcha
          grecaptcha.reset();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style>
.avisoCaptcha{
  display: none;
}
#containerRecaptcha{
  display: inline-table;
  text-align:center;
}
#containerRacapBut{
  width: 100%;
  text-align: center;
}
.mx_form_invalido .mx_recaptcha_failed span{
  display: block;
  color: red;
  font-weight: bold;
}
#recapContato{
  margin-bottom: 18px;
  width: 100%;
  text-align: center;
}
</style>
