<template>
  <b-container class="container">
    <div class="busca-principal-internas mt-3">
      <b-form @submit="onHandleSearch" class="input-group">
        <b-form-input
          id="term"
          v-model="term"
          type="text"
          required
          placeholder="Busque pelo curso desejado"
        ></b-form-input>
        <div class="input-group-append">
          <button type="submit" class="btn btn-enviar">
            Buscar
          </button>
        </div>
      </b-form>
    </div>
  </b-container>

  <!-- // busca-principal-->
</template>

<script>
export default {
  name: "SearchBar",
  props: ["isCursoPage"],
  data() {
    return {
      term: "",
    };
  },
  methods: {
    onHandleSearch(evt) {
      evt.preventDefault();
      if (this.term && this.term.trim().length > 2) {
        this.$router.push({
          path: "/busca",
          query: { curso: this.term.trim() },
        });
      }
    },
  },
};
</script>

<style></style>
