<template>
  <div id="contato">
    <PagePanel :title="titlePage">
      <template #breadcrumb>
        <Breadcrumb classNav="breadcrumb-paginas" :items="itemsBreadcrumb" />
      </template>
    </PagePanel>
    <main class="paginas-internas bg-white d-flex flex-column justify-content-center align-items-center w-100" role="main">
      <b-row class="row-dados w-100">
        <div class="col-12 mt-4 d-flex justify-content-center">
          <div class="bg-white container-verificacao-topo">
            <b-row class="mb-4">
              <div class="col-12">

                <h2 class="subTitulo-padrao-paginas mb-4 text-center">Selecione uma das opções abaixo:</h2>
                <b-row>
                  <div class="col-md-6 col-lg-4 col-docs">
                    <div class="box-documentos d-flex">
                      <b-link href="https://portal.faculdadebookplay.edu.br" class="d-flex align-items-center">
                        <div class="icone-documento">
                          <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z M 15 22 L 15 24 L 35 24 L 35 22 Z M 15 28 L 15 30 L 31 30 L 31 28 Z M 15 34 L 15 36 L 35 36 L 35 34 Z"/></svg>
                        </div>
                        <span>Requisitar / Imprimir Documentos</span>
                      </b-link>
                    </div>
                  </div>
                  <!-- <div class="col-md-6 col-lg-4 col-docs">
                    <div class="box-documentos d-flex">
                      <b-link to="/acesso" class="d-flex align-items-center">
                        <div class="icone-documento">
                          <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z M 15 22 L 15 24 L 35 24 L 35 22 Z M 15 28 L 15 30 L 31 30 L 31 28 Z M 15 34 L 15 36 L 35 36 L 35 34 Z"/></svg>
                        </div>
                        <span>Imprimir Documentos</span>
                      </b-link>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-6 col-lg-4 col-docs">   MODIFICADO DIA 08/08/22
                    <div class="box-documentos d-flex">
                      <b-link to="/baixarArquivos" class="d-flex align-items-center">
                        <div class="icone-documento">
                          <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z M 15 22 L 15 24 L 35 24 L 35 22 Z M 15 28 L 15 30 L 31 30 L 31 28 Z M 15 34 L 15 36 L 35 36 L 35 34 Z"/></svg>
                        </div>
                        <span>Arquivos Acadêmicos</span>
                      </b-link>
                    </div>
                  </div> -->
                </b-row>
              </div>
            </b-row>
          </div>
        </div>
      </b-row>
    </main>
  </div>
</template>

<script>
import Breadcrumb from "@/components/shared/Breadcrumb";
import FormContato from "@/components/contato/FormContato";
import PagePanel from "@/components/shared/PagePanel";
import {
  CONTATO_TITLE,
  TEL_WHATSAPP_VENDAS,
  TEL_WHATSAPP_ATENDIMENTO
} from "../constants/Constants";
import { getLinkWhastapp } from "@/utils/Utils";

//import VueScrollTo from "vue-scrollto";
//const VueScrollTo = require('vue-scrollto');

export default {
  components: { Breadcrumb, FormContato, PagePanel },
  data() {
    return {
      titlePage       : "Documentos Institucionais",
      itemsBreadcrumb : [
        {
          text: "Home",
          to: "/"
        },
        {
          text: "Documentos Institucionais",
          to: "#",
          active: true
        }
      ],
    };
  },
  mounted() {
    this.setPageDetails();
  },
  watch: {
    // call again the method if the route changes
    $route: "setPageDetails"
  },
  methods: {
    setPageDetails() {
      this.titlePage = this.$route.params.id
        ? CONTATO_TITLE[this.$route.params.id]
        : "Documentos Institucionais";
      //this.scrollToForm();
    }
  }
};
</script>

<style scoped>
.col-docs {
  margin-bottom: 15px;
}
.box-documentos span {
	display: block;
	font-family: "Fira Sans", sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #004F9F;
  margin-left: 5px;
}
.box-documentos {
  display: flex;
  border-radius: 8px;
  border: 2px solid #004F9F;
}
.icone-documento {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px; 
  border-bottom-left-radius: 8px;
  width: 95px;
  height: 103px;
  background-color: #004F9F;
  position: relative;
  left: -2px;
}
.subTitulo-padrao-paginas {
	font-family: "Fira Sans", sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 34px;
	color: #004F9F;
}
.container-verificacao-topo {
  width: 100%;
  max-width: 1010px;
}

</style>
